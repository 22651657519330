/* The typing effect */
@keyframes typing {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }

  50% {
    border-color: #bcadf7;
  }
}

.background {
  top: 70px;
  position: absolute;
  /* max-width: 3000px; */
  background-image: url('../img/backgroundImage.svg');
  background-repeat: no-repeat;
  background-size: cover;
  /* background-clip: 100vw; */
  width: 100%;
  height: 92%;
  /* background-position: center; */
  z-index: -1;
  filter: grayscale(100%);
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.body {
  position: relative;
  max-width: 3000px;
  width: 100%;
}

.header {
  width: 100%;
  position: relative;
  max-width: 3000px;
  padding: 18px 30px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e5e5e5;
}

.header_menu,
.header_item {
  display: none;
}

.header_img {
  height: 40px;
}

.header_img img {
}

.header_btn > button {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  border: none;
  margin-left: 40px;
}

.header_btn :last-child {
  background-color: var(--main-color);
  color: #fff;
  padding: 10px 48px;
}

.header_btn button:nth-child(1) {
  margin-left: 0;
}

.header_btn :nth-child(1),
.header_btn :nth-child(2) {
  color: var(--main-color);
  background-color: #fff;
}

.body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.intro {
  max-width: 3000px;
  margin: auto;
  padding: 73px 10.488vw;
  display: flex;
  flex-direction: column;
}

.intro_wallet {
  width: 100%;
  box-sizing: border-box;
  justify-content: space-between;
}

.intro_wallet-text {
  margin-right: 150px;
  width: 544px;
  align-items: center;
}

.intro_wallet-text h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 64px;
  line-height: 72px;
  letter-spacing: -2px;
  color: #353945;
  margin: 0;
  padding-top: 73px;
}

h2 span {
  border-right: 5px solid #bcadf7;
  /* The typwriter cursor */
  animation: typing 3.5s steps(40, end), blink-caret 0.75s step-end infinite;
}

.intro_wallet-text p {
  margin: 20px 0;
}

.intro_wallet-text button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--main-color);
  border: none;
  color: #fff;
  height: 50px;
  width: 252px;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  border-radius: 30px;
}

.intro_wallet-text-btn {
  padding-left: 10px;
}

.intro_wallet-img {
  height: 500px;
}

/*  */

.intro_buy {
  width: 100%;
  margin-top: 85px;
  /* justify-content: space-between; */
  /* align-items: center; */
}

.intro_buy img {
  width: 320px;
  margin-left: 73px;
  padding-bottom: 194px;
}

.intro_buy div {
  padding-left: 91px;
}

.intro_buy h4,
.intro_explore h4 {
  margin: 0;
  font-weight: 500;
  font-size: 22px;
  line-height: 32px;
  height: 32px;
}

.intro_buy p,
.intro_explore p {
  max-width: 358px;
  margin-top: 19px;
}

/*  */

.intro_explore {
  width: 100%;
  padding-right: 124px;
  margin-top: 150px;
  align-items: flex-end;
}

.intro_explore img {
  padding-top: 200px;
  margin-right: 63px;
  width: 280px;
}

/* 
 */
.get {
  width: 100%;
  max-width: 3000px;
  margin-top: 81px;
  width: 100%;
  height: 300px;
  background-color: #110933;
  align-items: center;
}

.get h1 {
  padding-top: 91px;
  margin: 0;
  color: #fff;
  font-weight: 700;
  font-size: 44px;
  line-height: 24px;
  text-align: center;
  padding-bottom: 65px;
}

.get button {
  color: #fff;
  border: 2px solid #fff;
  width: 240px;
  height: 56px;
  background-color: transparent;
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
}

/*  */
.about {
  width: 100%;
  max-width: 2000px;
  /* margin: auto; */
  padding-top: 41px;
  padding-left: 10%;
  padding-right: 98px;
  justify-content: space-between;
}

.about img {
  margin-top: 101px;
  width: 677px;
  max-width: 40vw;
}

.about_img-check {
  display: none;
}

.about_text {
  width: 100%;
  padding-left: 100px;
  padding-top: 167px;
}

.about_text h3 {
  font-weight: 700;
  font-size: 48px;
  line-height: 56px;
  color: #23262f;
  margin: 0;
  letter-spacing: -0.2px;
  padding-bottom: 50px;
}

.about_text img {
  margin-right: 24px;
  margin-top: 42px;
  width: 56px;
  height: 56px;
}

.about_text h5 {
  letter-spacing: -0.75px;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  padding-bottom: 10px;
  margin: 0;
}

/* .about_text-astrone, .about_text-choose {
  align-items: center;
} */

.about_text-astrone p,
.about_text-choose p {
  font-size: 14px;
  max-width: 358px;
}

.about_text-line {
  background: #e6e8ec;
  height: 1px;
  margin: 32px 0;
}

/*  */

.doc {
  width: 100%;
  max-width: 3000px;
  margin-top: 133px;
  background: #f1f1f1;
  height: 248px;
  align-items: center;
}

.doc h3 {
  font-weight: 700;
  font-size: 40px;
  line-height: 24px;
  text-align: center;
  color: #23262f;
  margin: 68px 0 44px 0;
}

.doc button {
  width: 240px;
  height: 56px;
  color: #fff;
  background: #110933;
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
}

/* 
 */

.partner {
  width: 100%;
  max-width: 3000px;
}

.partner h2 {
  font-weight: 700;
  font-size: 48px;
  line-height: 28px;
  color: #23262f;
  text-align: center;
  padding-top: 116px;
  padding-bottom: 99px;
  margin: 0;
}

.partner div {
  box-sizing: border-box;
  gap: 40px;
  padding: 0 calc(10.5vw - 60px) 116px calc(10.5vw - 60px);
  justify-content: center;
  gap: 122px;
}

.partner img {
  max-height: 72px;
  height: 5vw;
  cursor: pointer;
}

.footer {
  width: 100%;
  max-width: 3000px;
  height: 100px;
  padding: 0 10.486vw;
  background: #110933;
  justify-content: space-between;
  align-items: center;
}

.footer p {
  align-items: center;
  font-size: 14px;
  line-height: 28px;
  font-weight: 700;
  color: #ffffff;
  cursor: pointer;
}

.footer h3 {
  margin: 0;
}

.footer a {
  text-decoration: none;
  color: #fff;
}

.footer :first-child {
  align-items: center;
}

.footer :first-child img {
  width: 31px;
  height: 26px;
}

.footer :first-child :nth-child(2) {
  padding: 0 28px 0 36px;
}

#copyright {
  font-weight: 400;
  font-size: 12px;
  color: #969696;
}

@media screen and (min-width: 1560px) {
  .intro {
    padding: 73px 15vw;
  }

  .about {
    width: 100%;
    max-width: 2000px;
    padding-top: 41px;
    padding-left: 14%;
    padding-right: 7%;
  }

  .partner {
    /* padding: 0 12vw 4.1rem 12vw; */
  }
}
